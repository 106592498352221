function getEguildIdAsync()
{
    if (typeof uicontroller === 'undefined' || typeof uicontroller.GetPharmacyEGuildID !== "function")
    {
        console.log("uicontroller.GetPharmacyEGuildID not defined");
        return null;
    }
    else
    {
        return uicontroller.GetPharmacyEGuildID();
    }
}

function getAuthTokenAsync()
{
    if (typeof uicontroller === 'undefined' || typeof uicontroller.GetMedAdvisorIdentityToken !== "function")
    {
        console.log("uicontroller.GetMedAdvisorIdentityToken not defined");
        return null;
    }
    else
    {
        return uicontroller.GetMedAdvisorIdentityToken();
    }
}